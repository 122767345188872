
//
// Local vars
// --------------------------------------------------

@gap:                   15px;
@item-link-color:       @link-color;
@item-link-color-hover: @link-hover-color;



//
// Items
// --------------------------------------------------

.ia-sorting {
  .clearfix();
  margin-bottom: @gap;
  padding: 10px;
  background: #f2f2f2;
}

.alpha-sorting {

  a {
    width: 40px;
    margin: 0 0 3px 0;
  }
}

// lists
.ia-items { 
  
  + .box {
    margin-top: @vertical-rhythm;
  }
}

.ia-items--cards {
  
}



//
// Single item
// --------------------------------------------------

.ia-item {
  .clearfix();
  position: relative;

  + .ia-item {
    margin-top: @gap+@gap;
  }

  a:not(.btn),
  a:not(.btn):focus {
    color: @item-link-color;

    &:hover {
      color: @item-link-color-hover;
    }
  }

  &--border {
    border: 1px solid @border-color;
    padding: @gap;
  }

  &--border-bottom {
    border-bottom: 1px solid @border-color;
    padding-bottom: @gap;

    & + & {
      margin-top: @gap;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &--featured {
    border: 1px solid @brand-info;
    padding: @gap;
    background: fade(@brand-info, 10%);
  }

  &--sponsored {
    border: 1px solid @brand-warning;
    padding: @gap;
    background: fade(@brand-warning, 10%);
  }

  &--inactive,
  &--hidden {
    background: #f5f5f5;
  }

  &__image {
    position: relative;
    text-align: center;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    img {
      .img-responsive();
      margin: 0 auto;
    }

    @media (min-width: @screen-phone) {
      text-align: left;
      float: left;
      margin-right: @gap;
      max-width: 100px;  
    }

    @media (min-width: @grid-float-breakpoint) {
      max-width: 150px;
    }

    &__badge {
      color: #fff;
      font-size: 12px;
      display: block;
      background: #222;
      padding: 3px 6px;
      position: absolute;
      left: 10px;
      bottom: 10px;
    }
  }

  &__content {
    overflow: hidden;

    > *:last-child {
      margin-bottom: 0;
    }

    &__info {
      float: right;
      margin-left: @gap;
      color: fade(@text-color, 50%);
    }
  }

  &__actions {
    float: right;

    a {
      margin-left: @gap;
    }

    a:not(.btn),
    a:not(.btn):focus {
      color: @text-color;

      &:hover {
        color: lighten(@text-color, 10%);
      }
    }
  }

  &__title {
    .font-normalize(18px, 0 0 @gap);
    font-weight: normal;
  }

  &__additional {
    .clearfix();

    p {
      float: left;
      color: fade(@text-color, 50%);
      margin: 0 @gap @gap 0;

      a:not(.btn) {
        color: fade(@text-color, 50%);

        &:hover {
          color: fade(@text-color, 70%);
        }
      }

      @media (min-width: @grid-float-breakpoint) {
        margin: 0 @gap+@gap @gap 0;
      }
    }

    &__icon {
      display: inline-block;
      border: 2px solid fade(@text-color, 30%);
      width: 34px;
      height: 34px;
      line-height: 30px;
      font-size: 18px;
      text-align: center;
      border-radius: 50%;

      &:hover {
        border-color: fade(@text-color, 70%);
      }
    }
  }

  &__table {

    >thead>tr>th, 
    >tbody>tr>th, 
    >tfoot>tr>th, 
    >thead>tr>td, 
    >tbody>tr>td, 
    >tfoot>tr>td {
      border-top: none;
      padding: 0 8px 8px 0;

      &:first-child {
        color: fade(@text-color, 50%);
        width: 18%;
      }
    }
  }
}

.ia-item.has-panel {
  .ia-item__image {
    margin-bottom: @gap;
  }
}

.ia-item__panel {
  clear: both;
  .clearfix();
  background: #f5f5f5;
  padding: 5px 10px;
  margin: @gap 0 0;
  font-size: 12px;

  &__item {
    display: inline-block;
    margin-right: @gap;
    color: fade(@text-color, 70%);

    &.pull-right {
      margin-right: 0;
      margin-left: @gap;
    }
  }
}

// as card

.ia-item--card {
  .ia-item__image {
    float: none;
    display: block;

    @media (min-width: @screen-phone) {
      max-width: 100%;
      margin: 0 0 @gap;

      img {
        margin: 0;
      }
    }

    @media (min-width: @grid-float-breakpoint) {
      max-width: 100%;
    }
  }

  .ia-item__content {

  }
}



//
// Labels
// --------------------------------------------------

.ia-item__labels {
  position: absolute;
  left: 15px;
  bottom: 100%;
  margin-bottom: -6px;
}

.label-available {
  .label-variant(@label-success-bg);
}

.label-approval {
  .label-variant(spin(@label-warning-bg, 15%));
}

.label-sold {
  .label-variant(@label-danger-bg);
}

.ia-item--sold {
  border-color: @brand-danger;
}

.label-rented {
  .label-variant(#999);
}

.label-inactive,
.label-hidden {
  .label-variant(@label-default-bg);
}

.ia-item--rented,
.ia-item--hidden {
  .opacity(.7);
}



//
// Categories
// --------------------------------------------------

.ia-categories {

  @media (max-width: @grid-float-breakpoint) {
    .row {
      [class*="col-md"] + [class*="col-md"] {
        margin-top: 0px;
      }
    }
  }
}