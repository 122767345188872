
//
// Gaps
// --------------------------------------------------

@spacer:    30px;
@spacer-y:  30px;
@spacer-x:  30px;

.m-a-0 { margin:        0 !important; }
.m-t-0 { margin-top:    0 !important; }
.m-r-0 { margin-right:  0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-l-0 { margin-left:   0 !important; }

.m-a { margin:        @spacer !important; }
.m-t { margin-top:    @spacer-y !important; }
.m-r { margin-right:  @spacer-x !important; }
.m-b { margin-bottom: @spacer-y !important; }
.m-l { margin-left:   @spacer-x !important; }
.m-x { margin-right:  @spacer-x !important; margin-left: @spacer-x !important; }
.m-y { margin-top:    @spacer-y !important; margin-bottom: @spacer-y !important; }
.m-x-auto { margin-right: auto !important; margin-left: auto !important; }

.m-a-md { margin:        (@spacer-y * 1.5) !important; }
.m-t-md { margin-top:    (@spacer-y * 1.5) !important; }
.m-r-md { margin-right:  (@spacer-y * 1.5) !important; }
.m-b-md { margin-bottom: (@spacer-y * 1.5) !important; }
.m-l-md { margin-left:   (@spacer-y * 1.5) !important; }
.m-x-md { margin-right:  (@spacer-x * 1.5) !important; margin-left:   (@spacer-x * 1.5) !important; }
.m-y-md { margin-top:    (@spacer-y * 1.5) !important; margin-bottom: (@spacer-y * 1.5) !important; }

.m-a-lg { margin:        (@spacer-y * 2) !important; }
.m-t-lg { margin-top:    (@spacer-y * 2) !important; }
.m-r-lg { margin-right:  (@spacer-y * 2) !important; }
.m-b-lg { margin-bottom: (@spacer-y * 2) !important; }
.m-l-lg { margin-left:   (@spacer-y * 2) !important; }
.m-x-lg { margin-right:  (@spacer-x * 2) !important; margin-left:   (@spacer-x * 2) !important; }
.m-y-lg { margin-top:    (@spacer-y * 2) !important; margin-bottom: (@spacer-y * 2) !important; }

.p-a-0 { padding:        0 !important; }
.p-t-0 { padding-top:    0 !important; }
.p-r-0 { padding-right:  0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.p-l-0 { padding-left:   0 !important; }

.p-a { padding:        @spacer !important; }
.p-t { padding-top:    @spacer-y !important; }
.p-r { padding-right:  @spacer-x !important; }
.p-b { padding-bottom: @spacer-y !important; }
.p-l { padding-left:   @spacer-x !important; }
.p-x { padding-right:  @spacer-x !important; padding-left:   @spacer-x !important; }
.p-y { padding-top:    @spacer-y !important; padding-bottom: @spacer-y !important; }

.p-a-md { padding:        (@spacer-y * 1.5) !important; }
.p-t-md { padding-top:    (@spacer-y * 1.5) !important; }
.p-r-md { padding-right:  (@spacer-y * 1.5) !important; }
.p-b-md { padding-bottom: (@spacer-y * 1.5) !important; }
.p-l-md { padding-left:   (@spacer-y * 1.5) !important; }
.p-x-md { padding-right:  (@spacer-x * 1.5) !important; padding-left:   (@spacer-x * 1.5) !important; }
.p-y-md { padding-top:    (@spacer-y * 1.5) !important; padding-bottom: (@spacer-y * 1.5) !important; }

.p-a-lg { padding:        (@spacer-y * 2) !important; }
.p-t-lg { padding-top:    (@spacer-y * 2) !important; }
.p-r-lg { padding-right:  (@spacer-y * 2) !important; }
.p-b-lg { padding-bottom: (@spacer-y * 2) !important; }
.p-l-lg { padding-left:   (@spacer-y * 2) !important; }
.p-x-lg { padding-right:  (@spacer-x * 2) !important; padding-left:   (@spacer-x * 2) !important; }
.p-y-lg { padding-top:    (@spacer-y * 2) !important; padding-bottom: (@spacer-y * 2) !important; }

.m-a-5 { margin:        5px !important; }
.m-t-5 { margin-top:    5px !important; }
.m-r-5 { margin-right:  5px !important; }
.m-b-5 { margin-bottom: 5px !important; }
.m-l-5 { margin-left:   5px !important; }
.m-x-5 { margin-right:  5px !important; margin-left:   5px !important; }
.m-y-5 { margin-top:    5px !important; margin-bottom: 5px !important; }

.m-a-10 { margin:        10px !important; }
.m-t-10 { margin-top:    10px !important; }
.m-r-10 { margin-right:  10px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-l-10 { margin-left:   10px !important; }
.m-x-10 { margin-right:  10px !important; margin-left:   10px !important; }
.m-y-10 { margin-top:    10px !important; margin-bottom: 10px !important; }

.m-a-15 { margin:        15px !important; }
.m-t-15 { margin-top:    15px !important; }
.m-r-15 { margin-right:  15px !important; }
.m-b-15 { margin-bottom: 15px !important; }
.m-l-15 { margin-left:   15px !important; }
.m-x-15 { margin-right:  15px !important; margin-left:   15px !important; }
.m-y-15 { margin-top:    15px !important; margin-bottom: 15px !important; }

.m-a-20 { margin:        20px !important; }
.m-t-20 { margin-top:    20px !important; }
.m-r-20 { margin-right:  20px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-l-20 { margin-left:   20px !important; }
.m-x-20 { margin-right:  20px !important; margin-left:   20px !important; }
.m-y-20 { margin-top:    20px !important; margin-bottom: 20px !important; }

.m-a-25 { margin:        25px !important; }
.m-t-25 { margin-top:    25px !important; }
.m-r-25 { margin-right:  25px !important; }
.m-b-25 { margin-bottom: 25px !important; }
.m-l-25 { margin-left:   25px !important; }
.m-x-25 { margin-right:  25px !important; margin-left:   25px !important; }
.m-y-25 { margin-top:    25px !important; margin-bottom: 25px !important; }

.p-a-5 { padding:        5px !important; }
.p-t-5 { padding-top:    5px !important; }
.p-r-5 { padding-right:  5px !important; }
.p-b-5 { padding-bottom: 5px !important; }
.p-l-5 { padding-left:   5px !important; }
.p-x-5 { padding-right:  5px !important; padding-left:   5px !important; }
.p-y-5 { padding-top:    5px !important; padding-bottom: 5px !important; }

.p-a-10 { padding:        10px !important; }
.p-t-10 { padding-top:    10px !important; }
.p-r-10 { padding-right:  10px !important; }
.p-b-10 { padding-bottom: 10px !important; }
.p-l-10 { padding-left:   10px !important; }
.p-x-10 { padding-right:  10px !important; padding-left:   10px !important; }
.p-y-10 { padding-top:    10px !important; padding-bottom: 10px !important; }

.p-a-15 { padding:        15px !important; }
.p-t-15 { padding-top:    15px !important; }
.p-r-15 { padding-right:  15px !important; }
.p-b-15 { padding-bottom: 15px !important; }
.p-l-15 { padding-left:   15px !important; }
.p-x-15 { padding-right:  15px !important; padding-left:   15px !important; }
.p-y-15 { padding-top:    15px !important; padding-bottom: 15px !important; }

.p-a-20 { padding:        20px !important; }
.p-t-20 { padding-top:    20px !important; }
.p-r-20 { padding-right:  20px !important; }
.p-b-20 { padding-bottom: 20px !important; }
.p-l-20 { padding-left:   20px !important; }
.p-x-20 { padding-right:  20px !important; padding-left:   20px !important; }
.p-y-20 { padding-top:    20px !important; padding-bottom: 20px !important; }

.p-a-25 { padding:        25px !important; }
.p-t-25 { padding-top:    25px !important; }
.p-r-25 { padding-right:  25px !important; }
.p-b-25 { padding-bottom: 25px !important; }
.p-l-25 { padding-left:   25px !important; }
.p-x-25 { padding-right:  25px !important; padding-left:   25px !important; }
.p-y-25 { padding-top:    25px !important; padding-bottom: 25px !important; }



//
// Flexbox
// --------------------------------------------------

.flexbox, .col-flex { display: flex; }

.align-items-top    { align-items: flex-start; }
.align-items-center { align-items: center; }
.align-items-bottom { align-items: flex-end; }



//
// Text
// --------------------------------------------------

.text-fade-25 { color: fade(@text-color, 25%) }
.text-fade-50 { color: fade(@text-color, 50%) }
.text-fade-75 { color: fade(@text-color, 75%) }

.text-italic    { font-style: italic !important; }
.text-white     { color: #fff !important; }
.text-overflow  { .text-overflow(); }
.text-uppercase { text-transform: uppercase !important; }



//
// Resets
// --------------------------------------------------

.no-bg           { background: none !important; }
.no-bg-color     { background-color: transparent !important; }
.no-border       { border: none !important; }
.no-border-color { border-color: transparent !important; }