
//
// Crutches
// --------------------------------------------------

.js-iad + .js-iad {
  .form-control();
  margin-top: 15px;
}



//
// Cursor pointer for some elems
// --------------------------------------------------

.js-datepicker-toggle {
  cursor: pointer;
}