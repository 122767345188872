
//
// Header
// --------------------------------------------------

.header {
  background: #2d2d30 url('../img/bg-header.jpeg') 50% 50% no-repeat;
  background-size: cover;
}

.teaser {
  padding: 100px 0;
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #2a2d36;
    opacity: .54;
  }

  > .container {
    position: relative;
    z-index: 15;
  }

  h1,
  h2 {
    color: #fff;
    font-weight: 100;
    .font-normalize(28px);
  }

  h2 {
    .font-normalize(16px, 5px 0 0);
    font-weight: 100;
  }

  @media (min-width: @grid-float-breakpoint) {
    padding: 250px 0;

    h1 {
      .font-normalize(65px);
      font-weight: 100;
    }

    h2 {
      .font-normalize(35px, 20px 0 0);
    }
  }
}
