.ia-form-filters {

  .row {
    margin-left: -5px;
    margin-right: -5px;

    [class^="col-md"] {
      padding-left: 5px;
      padding-right: 5px;
    } 
  }

  .form-group {
    position: relative;
  }

  &__apply {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    display: none;
    text-transform: uppercase;
    border: 0 none;
    border-radius: @border-radius-small;
    background: @brand-warning;
    padding: 2px 6px;
    color: #fff;
    font-size: 10px;

    &:focus {
      outline: 0 none;
      background: darken(@brand-warning, 3%);
    }

    &::before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: -8px;
      border-style: solid;
      border-width: 4px 8px 4px 0;
      border-color: transparent @brand-warning transparent transparent;
    }
  }

  &__actions {
    background: #f2f2f2;
    margin: 20px -20px -20px;
    padding: 20px;
  }
}

.is-list-loading {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: fade(#fff, 80%);
    z-index: 500;
  }

  .list-spinner {
    position: absolute;
    z-index: 501;
    left: 50%;
    top: 50%;
    .translate(-50%; -50%);
  }
}

@list-spinner-color: @brand-primary;

.list-spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.list-spinner > div {
  width: 18px;
  height: 18px;
  background-color: @list-spinner-color;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.list-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.list-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}