
//
// Aliases for compatibility
// --------------------------------------------------

.@{fa-css-prefix}-action-print:before { content: @fa-var-print; }
.@{fa-css-prefix}-action-edit:before { content: @fa-var-pencil; }
.@{fa-css-prefix}-action-delete:before { content: @fa-var-remove; }
.@{fa-css-prefix}-action-report:before { content: @fa-var-exclamation-triangle; }
.@{fa-css-prefix}-action-visit:before { content: @fa-var-external-link; }
.@{fa-css-prefix}-live:before { content: @fa-var-windows; }