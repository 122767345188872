.pricing-table {
  margin: 30px 0;

  [class^="col-md-"] {
    padding: 0;

    &:first-child {
      padding-left: 15px;
      padding-top: 40px;
    }

    &:last-child {
      padding-right: 15px;
      padding-top: 40px;
    }
  }

  &__item {
    border: 1px solid #eee;
    padding: 30px;
    border-radius: 3px;
    text-align: center;

    &__title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
    }

    &__subtitle {
      color: fade(@text-color, 50%);
    }

    &__price {
      font-size: 40px;
      font-weight: bold;
      color: @brand-success;
      padding: 30px;
      margin: 30px -30px;
      background: fade(@brand-info, 10%);

      sup {
        font-size: 16px;
        color: fade(@text-color, 50%);
      }
    }

    &__features {
      margin-bottom: 30px;

      ul {
        list-style-type: none;
        margin: 0 20px;
        padding: 0;

        > li {
          margin: 0;
          padding: 12px 0;
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: 0 none;
          }
        }
      }
    }

    &__actions {

    }

    // modifiers

    &--featured {
      padding-top: 70px;
      padding-bottom: 70px;
      .box-shadow(0 0 25px rgba(0,0,0,.1));
      position: relative;
      z-index: 20;
    }
  }
}