
//
// Vars
//
// you can also redefine bootstrap vars here
//
// it is always better to copy bootstrap vars here
// so that you will know where it is.
// --------------------------------------------------

// template colors

@brand-dark:       #323232;
@brand-primary:    #00bff3;
@link-color:       @brand-primary;
@link-hover-color: darken(@link-color, 15%);
@body-bg:          #fff;
@headings-color:   inherit;
@border-color:     #eee;

// rhythm
@vertical-rhythm:  30px;
@sections-v-pad:   150px;

// font
@import url('//fonts.googleapis.com/css?family=Roboto:100,400,400i,700,700i&subset=cyrillic');

@font-family-base: 'Roboto', @font-family-sans-serif;

@border-radius-base:        3px;
@border-radius-large:       4px;
@border-radius-small:       2px;



//
// Inventory
// --------------------------------------------------

@inventory-bg:                 #fff;
@inventory-height:             50px;
@inventory-border-color:       #eee;
@inventory-base-color:         #000;
@inventory-link-color:         fade(@inventory-base-color, 45%);
@inventory-link-hover-color:   fade(@inventory-base-color, 50%);
@inventory-link-active-color:  fade(@inventory-base-color, 70%);
@inventory-social-link-color:  fade(@inventory-base-color, 70%);
@inventory-text-color:         fade(@inventory-base-color, 30%);



//
// Navbar
// --------------------------------------------------

@navbar-height:                    100px;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-default-bg:                #fff;
@navbar-default-border:            #eee;

// Navbar links
@navbar-default-link-color:                fade(@brand-dark, 80%);
@navbar-default-link-hover-color:          fade(@brand-dark, 60%);
@navbar-default-link-hover-bg:             transparent;
@navbar-default-link-active-color:         @brand-primary;
@navbar-default-link-active-bg:            transparent;
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;



//
// Breadcrumbs
// --------------------------------------------------

@breadcrumb-padding-vertical:        10px;
@breadcrumb-padding-horizontal:      0;



//
// Boxes
// --------------------------------------------------

@box-caption-color:  @headings-color;
@box-content-color:  @text-color;



//
// Forms
// --------------------------------------------------

@fieldest-padding-vertical:   20px;
@fieldest-padding-horizontal: 20px;

@form-label-color:            fade(@text-color, 80%);

@input-border:                #ccc;
@input-border-focus:          darken(@input-border, 35%);



//
// Dropdowns
// --------------------------------------------------

@dropdown-border-radius:  0;
@dropdown-link-color:     fade(@text-color, 70%);
@dropdown-link-hover-bg:  #f5f5f5;



//
// List group
// --------------------------------------------------

@list-group-border-radius:         0;
@list-group-border:                @border-color;




//
// Footer
// --------------------------------------------------

@footer-bg:                 @brand-dark;
@footer-height:             60px;
@footer-link-color:         fade(#fff, 45%);
@footer-link-hover-color:   fade(#fff, 50%);
@footer-link-active-color:  fade(#fff, 70%);
@footer-social-link-color:  fade(#fff, 70%);
@footer-text-color:         fade(#fff, 30%);
