//
// Default navbar
// --------------------------------------------------

.navbar-default {
  margin: 0;
  border-radius: 0;
  border: 0 none;
  background-color: @navbar-default-bg;
  .box-shadow(0 1px 3px rgba(0,0,0,.18));
}

.navbar-sticky {
  .transition(all .15s ease-in-out);

  &.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    .box-shadow(0 1px 6px rgba(0,0,0,.18));
  }

  .navbar-collapse {
    max-height: 320px;
  }
}



//
// Brand
// --------------------------------------------------

.navbar-brand {
  text-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  height: @navbar-height;
  line-height: @navbar-height;
  font-weight: bold;
  color: @brand-dark !important;
  text-transform: uppercase;

  > img {
    display: inline-block;
    max-height: @navbar-height;
  }
}



//
// Main navigation links
// --------------------------------------------------

.navbar-nav {

  // Uncollapsed state
  @media (min-width: @grid-float-breakpoint) {
    &.navbar-right {
      margin-left: 30px;
    }

    > li {

      > a {
        padding: 0 10px;
        margin-top: @navbar-padding-vertical;
        margin-bottom: @navbar-padding-vertical;
      }
    }
  }

  @media (max-width: @grid-float-breakpoint) {
    margin: 0 -15px;

    li {

      > a {
        border-bottom: 1px solid #eee;
      }
    }
  }
}

.nav-account {

  .dropdown-toggle {

    > img {
      width: 20px;
      height: 20px;
      overflow: hidden;
      display: block;
      float: left;
      margin-right: 5px;
    }
  }
}



//
// Search
// --------------------------------------------------

.search-navbar {
  position: relative;
  margin: 0;
  padding: 0;
  height: @navbar-height;

  &.pull-right {
    margin-left: 50px;
    margin-right: -15px;
  }

  &__toggle {
    position: relative;
    line-height: @line-height-computed;
    height: @line-height-computed;
    width: @line-height-computed;
    border: 0 none;
    background: none;
    padding: 0;
    margin-top: @navbar-padding-vertical;
    margin-bottom: @navbar-padding-vertical;
    color: @navbar-default-link-color;
    font-size: 20px;

    &:focus {
      outline: 0 none;
    }

    &:hover {
      color: @navbar-default-link-hover-color;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      left: -20px;
      top: -10px;
      bottom: -10px;
      width: 1px;
      background: @navbar-default-border;
    }
  }

  .input-group {
    position: absolute;
    z-index: 1002;
    width: 320px;
    top: 100%;
    right: 0;
    background: #fff;
    padding: 16px;
    margin-top: 1px;
    opacity: 0;
    visibility: hidden;
    .transition(all .15s ease-in-out);

    &.is-visible {
      opacity: 1;
      visibility: visible;
      .box-shadow(0 1px 6px rgba(0,0,0,.18));
    }

    input {
      background: #f2f2f2;
      border-color: #f2f2f2;
    }
  }

  @media (max-width: @grid-float-breakpoint) {
    height: auto;

    &.pull-right {
      float: none !important;
      margin-left: -15px;
      margin-right: -15px;
    }

    &__toggle {
      display: none;
    }

    .input-group {
      position: static;
      opacity: 1;
      visibility: visible;
      width: auto;
      padding: 10px;
      margin-top: 0;
      background: #f6f6f6;
    }

  }
}

.search-navbar-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .navbar & {
    z-index: 999;
  }
}
