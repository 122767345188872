
//
// Notification floating box
// intelli.js - notifFloatBox
// --------------------------------------------------

.notifFloatBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15000;
  padding: @vertical-rhythm/2;
  display: none;
  background: @brand-info;
  color: #fff;
  
  ul {
    list-style-type: none;
    padding: 0; 
    margin: 0 30px 0 0;
  }

  a.close {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &--info {
    background: @brand-info;
  }

  &--success {
    background: @brand-success;
  }

  &--error {
    background: @brand-danger;
  }

  @media (min-width: @grid-float-breakpoint) {
    left: auto;
    right: 20px;
    top: 20px;
    max-width: 300px;
    padding: 20px;
    border-radius: 4px;
  }
}