
//
// Global styles
// --------------------------------------------------

.font-normalize(@size, @margin: 0) {
  font-size: @size;
  line-height: @size;
  margin: @margin;
}

body {
  background: @body-bg;
}

// improvements for cols in mobile version
@media (max-width: @grid-float-breakpoint) {
  .row {
    [class*="col-md"] + [class*="col-md"] {
      margin-top: 30px;
    }
  }
}



//
// Links
// --------------------------------------------------

// a {
//   text-decoration: underline;

//   &:hover,
//   &:focus,
//   &:active {
//     text-decoration: none;
//   }
// }



//
// Breadcrumbs
// --------------------------------------------------

.breadcrumbs {
  padding-top: @breadcrumb-padding-vertical;
  padding-bottom: @breadcrumb-padding-vertical;

  > .container {
    position: relative;

    &::before {
      display: block;
      position: absolute;
      left: 15px;
      bottom: -@breadcrumb-padding-vertical;
      right: 15px;
      height: 1px;
      background: #eee;
    }
  }

  .breadcrumb {
    float: left;
  }

  .action-buttons {
    float: right;
    margin-top: 7px;
    position: relative;
  }

  @media (max-width: @grid-float-breakpoint) {
    padding-top: 10px;
    padding-bottom: 10px;

    .breadcrumb {
      float: none;
      padding-top: 0;
    }

    .action-buttons {
      float: none;
      margin-top: 0;
    }
  }
}



//
// Content
// --------------------------------------------------

.page-header {
  margin-top: 0;
}

.verytop {
  background: #f2f2f2;
  padding: @vertical-rhythm 0;
}

.content {
  padding: @vertical-rhythm*2 0;
  background: #fff;

  &__header {
    .clearfix();
    // border-bottom: 1px solid #ddd;
    margin-bottom: @vertical-rhythm/2;
    padding-bottom: @vertical-rhythm/2;

    h1 {
      float: left;
      .font-normalize(28px, 0);
    }
  }

  &__actions {
    float: right;
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      float: left;

      > a {
        display: inline-block;
        line-height: 28px;
        margin-left: @vertical-rhythm/2;
        color: fade(@text-color, 50%);

        &:hover {
          color: fade(@text-color, 70%);
          text-decoration: none;
        }
      }
    }
  }

  &__body {

    + .box {
      margin-top: @vertical-rhythm*2;
    }
  }

  @media (max-width: @grid-float-breakpoint) {
    padding: @vertical-rhythm 0;
  }
}

.verybottom {
  padding: @vertical-rhythm*2 0;
  border-top: 1px solid #ddd;
}

.highlight {
  background: #fde35b;
}



//
// Navs
// --------------------------------------------------

.nav-inline {

  > li {
    float: left;

    > a {
      padding: 0;
      margin: 0 15px 0 0;

      &:hover {
        background: none;
      }
    }
  }
}
