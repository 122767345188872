
//
// Boxes / blocks
// --------------------------------------------------

.box {

  &--border {
    padding: 20px;
    border: 1px solid @border-color;
  }

  &--featured {
    border: 1px solid @brand-info;
    padding: @gap;
    background: fade(@brand-info, 10%);
  }

  &--sponsored {
    border: 1px solid @brand-warning;
    padding: @gap;
    background: fade(@brand-warning, 10%);
  }

  + .box,
  + div {
    margin-top: @vertical-rhythm;
  }

  &__caption {
    .font-normalize(18px, 0);
    color: @box-caption-color;
    position: relative;
  }

  &__content {
    color: @box-content-color;
    padding-top: @vertical-rhythm/2;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}



//
// Min max toggle
// --------------------------------------------------

.collapsible {
  position: relative;

  &.collapsed {

    .minmax-toggle i {
      .fa-icon();
      &:before { content: @fa-var-angle-down; }
    }

    .box__content,
    > .fieldset__content {
      display: none;
    }
  }
}

.minmax-toggle {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
  font-size: 20px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  color: fade(@text-color, 30%);

  i {
    .fa-icon();
    &:before { content: @fa-var-angle-up; }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: fade(@text-color, 50%);
  }
}