
//
// Plans
// --------------------------------------------------

.plans {

  &__item {
    border: 1px solid @border-color;
    padding: 5px;
    border-radius: @border-radius-base;

    & + & {
      margin-top: @vertical-rhythm/2;
    }

    &__header {
      background: #f2f2f2;
      margin: 0;
      padding: 5px 25px;
      cursor: pointer;

      input[type="radio"] {
        margin-top: 3px;
      }
    }

    &__body {
      .text-fade-50();
      padding: 10px;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      border-color: fade(@brand-success, 50%);

      .plans__item__header {
        background: fade(@brand-success, 20%);
      } 
    }
  }
}



//
// Gateway list
// --------------------------------------------------

.gw-list {
  display: none;
  .clearfix();

  &__item {
    float: left;
    margin: 10px 10px 0 0;
  }
}

.gateway {
  border: 1px solid @border-color;
  padding: 10px;
  border-radius: @border-radius-base;
  cursor: pointer;
}

